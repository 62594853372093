
import { useJsApiLoader } from '@react-google-maps/api'

const libMap = {
    libArr : ["places"]
}

const useLoadScript = () => {

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyC1S2yG1ZM0OFJoOh6yqOLIRzH47QoljCo',
        libraries: libMap.libArr
    });

    return { isLoaded }
}
export default useLoadScript