import './styles/changes.scss'
import './styles/main.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MapFeed from './mapFeed';

function App() {
  return (
   <>
    <Router>
      <Routes>
        <Route path='/:bookingId' Component={MapFeed}/>
      </Routes>
    </Router>
   </>
  );
}

export default App;
