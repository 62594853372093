import React, { useEffect } from "react";
import {
  GoogleMap,
  DirectionsService,
  DirectionsRenderer,
  Marker,
} from "@react-google-maps/api";
import useLoadScript from "./useLoadScript";

const containerStyle = {
  width: "350px",
  height: "250px",
};

function transformMarkers(markers) {
  let tMarkers = [];
  markers.forEach(([lng, lat], i) => (tMarkers[i] = { lat, lng }));
  return tMarkers;
}

function MapComp({ markers, showEta = false }) {
  const tMarkers = transformMarkers(markers);

  const center = tMarkers[0];

  const [map, setMap] = React.useState(null);
  const [isInitialized, setIsInitialized] = React.useState(false);

  const onLoad = React.useCallback(
    function callback(map) {
      const google = (window.google = window.google ? window.google : {});
      if (!isInitialized && google.maps) {
        setIsInitialized(true);
      }

      setMap(map);
    },
    [center, isInitialized]
  );

  const onUnmount = React.useCallback(function callback() {
    setMap(null);
  }, []);

  const [response, setResponse] = React.useState(null);
  const [eta, setEta] = React.useState(null);

  const directionsCallback = (result, status) => {
    if (status === "OK") {
      if (!response) {
        setResponse(result);
        if (
          result.routes &&
          result.routes.length > 0 &&
          result.routes[0].legs &&
          result.routes[0].legs.length > 0
        ) {
          const duration = result.routes[0].legs[0].duration.text;
          setEta(duration);
        }
      }
    } else {
      console.error("Directions request failed due to ", status);
    }
  };

  const directionsRequest = {
    origin: tMarkers[0], // Replace with your origin coordinates
    destination: tMarkers[1], // Replace with your destination coordinates
    travelMode: "DRIVING", // Change this if you want different travel mode (DRIVING, WALKING, BICYCLING, TRANSIT)
  };

  const { isLoaded } = useLoadScript();

  useEffect(() => {
    if (map) {
      const bounds = new window.google.maps.LatLngBounds();
      tMarkers.map((marker) => {
        bounds.extend(marker);
      });
      map.fitBounds(bounds);
    }
  }, [map, tMarkers]);

  let mapStyles = [
    {
      featureType: "administrative",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#54585c",
        },
      ],
    },
    {
      featureType: "landscape",
      elementType: "all",
      stylers: [
        {
          color: "#f7f7f7",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "all",
      stylers: [
        {
          visibility: "on",
        },
        {
          color: "#e5e5e5",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "labels.text",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "all",
      stylers: [
        {
          saturation: -100,
        },
        {
          lightness: 45,
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "all",
      stylers: [
        {
          visibility: "simplified",
        },
      ],
    },
    {
      featureType: "road.arterial",
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "transit",
      elementType: "all",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "all",
      stylers: [
        {
          color: "#ededed",
        },
        {
          visibility: "on",
        },
      ],
    },
  ];

  return isLoaded ? (
    <div>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={4}
        options={{
          disableDefaultUI: true,
          styles: mapStyles,
        }}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        <>
          <Marker
            position={center}
            options={{
              icon: {
                url: "https://i.imgur.com/3zLRgTC.png", // Replace with the path to your car icon image
                scaledSize: new window.google.maps.Size(30, 30),
              },
            }}
          />
          <Marker
            position={tMarkers[1]}
            options={{
              icon: {
                url: "https://i.imgur.com/Zi3rsOu.png", // Replace with the path to your destination icon image
                scaledSize: new window.google.maps.Size(30, 30),
              },
            }}
          />
          <DirectionsService
            options={{
              destination: directionsRequest.destination,
              origin: directionsRequest.origin,
              travelMode: directionsRequest.travelMode,
            }}
            callback={directionsCallback}
          />
          {response && (
            <DirectionsRenderer
              directions={response}
              options={{ suppressMarkers: true }}
            />
          )}
        </>
      </GoogleMap>
      {showEta && eta && (
        <p style={{ marginTop: "20px" }}>
          The driver will be arriving in <strong>{eta}</strong>
        </p>
      )}
    </div>
  ) : (
    <></>
  );
}

export default React.memo(MapComp);
