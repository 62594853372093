import { fetchEventSource } from "@microsoft/fetch-event-source";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Map from "./map";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";

function MapFeed() {
  const { bookingId } = useParams();
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const eventSource = fetchEventSource(
        `https://api.bhoogol.tech/bk/${bookingId}`,
        {
          method: "GET",
          headers: {
            Accept: "text/event-stream",
          },
          onopen(res) {
            if (res.ok && res.status === 200) {
              console.log("Connection made ", res);
            } else if (
              res.status >= 400 &&
              res.status < 500 &&
              res.status !== 429
            ) {
              console.log("Client side error ", res);
            }
          },
          onmessage(event) {
            const parsedData = JSON.parse(event.data);
            console.log(' - - -- --- - - ', parsedData);
            setData(parsedData);
          },
          onclose() {
            console.log("Connection closed by the server");
          },
          onerror(err) {
            console.log("There was an error from server", err);
          },
        }
      );

      return () => {
        // Cleanup function: Close the event source when the component unmounts
        eventSource.close();
      };
    };

    fetchData();

    // // Return a cleanup function to handle unmounting
    // return () => {
    //   // Add any cleanup operations here if needed
    // };
  }, []);
  return (
    <>
      {data && (
        <>
          {data.bookingStatus !== "Confirmed" ? (
            <div className="container">
              <div
                className="card text-bg-success mb-3"
                style={{ maxWidth: "18rem" }}
              >
                <div className="card-header">Hi!</div>
                <div className="card-body">
                  <h5 className="card-title">Booking already started</h5>
                  <p className="card-text">
                    Looks like you have already checked-in.
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div className="container mt-4">
              <p className="lead text-center">#{bookingId}</p>
              <div className="d-flex justify-content-center">
                {data.bookingDetails.cab?.location?.coordinates?.length ===
                  2 && (
                    <Map
                      showEta={true}
                      markers={[
                        data.bookingDetails.cab.location.coordinates,
                        data.bookingDetails.pickupLocation.coordinates,
                      ]}
                    />
                  )}
              </div>
              <div className="card border-dark mb-4">
                <div className="card-body">
                  <h6 className="card-subtitle mb-2">
                    <div className="d-flex justify-content-between">
                      <span>{data.bookingDetails.cab.registrationNumber}</span>
                    </div>
                  </h6>
                  <div className="card-text" style={{ fontSize: "12px" }}>
                    <div className="d-flex justify-content-between">
                      <span>
                        {data.bookingDetails.cab.make}{" "}
                        {data.bookingDetails.cab.model}
                      </span>

                      {/* <span>{data.driverDetails.user.name}</span>
                  <a href={`tel:${data.driverDetails.user.phoneNumber}`}><FontAwesomeIcon icon={faPhone}/></a> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="card mb-4">
                <div className="card-body">
                  <h2 className="card-title mb-2">
                    <div className="d-flex justify-content-center">
                      <span>{data.otp}</span>
                    </div>
                  </h2>
                  <div className="card-text" style={{ fontSize: "14px" }}>
                    <p style={{ fontSize: "12px", color: "grey" }}>Pickup</p>
                    <p>{data.bookingDetails.pickupLocation.address}</p>
                    <p style={{ fontSize: "12px", color: "grey" }}>Drop</p>
                    <p>{data.bookingDetails.dropoffLocation.address}</p>
                  </div>
                </div>
              </div>
              <div className="card text-bg-success">
                <div className="card-body">
                  <h6 className="card-subtitle text-body-secondary">
                    <div className="d-flex justify-content-between">
                      {data.driverDetails && <span >
                        {data.driverDetails.user.name} </span>
                      }

                      {data.driverDetails && <a href={`tel:${data.driverDetails.user.phoneNumber}`}>
                        <FontAwesomeIcon
                          size="lg"
                          color="blue"
                          icon={faPhone}
                        />
                      </a>}
                    </div>
                  </h6>
                  <div className="card-text" style={{ fontSize: "12px" }}>
                    <div className="d-flex justify-content-between">
                      <span>
                        {data.bookingDetails.taxiService.name}{" "}
                        <strong style={{ fontSize: "20px" }}>.</strong>{" "}
                        {data.bookingDetails.taxiService.contactInfo.address}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default MapFeed;
